import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../components/logo"
import BuyButton from "../components/buy"
import Footer from "../components/footer"
import { useStaticQuery, graphql } from "gatsby"
import Bunny from "../components/images/bunny"


const Language = "de-DE"


const IndexPage = () => {
  
  const data = useStaticQuery(graphql`
    query {
      bunnyImage: file(relativePath: { eq: "scotty_easter_bunny.jpg" }) {
        childImageSharp {
          resize(width: 600) {
            src
            height
            width
          }
        }
      }
    }
  `)

  return (

    <Layout>
      <SEO title="Osterpraline | Vegane Schokoladen | Bunny Wild Carrot" lang={Language} image={data.bunnyImage.childImageSharp.resize} defer={false}  />
      <Logo className="mx-auto h-auto w-56 pt-8 lg:absolute lg:z-10 lg:left-0 lg:ml-12" route="https://candide.berlin/startseite" />
      <div className="px-6 text-center md:flex md:items-end">
        <div className="md:w-1/2">
          <Bunny />
        </div>
        <div className="text-lg text-gray-800 font-light md:w-1/2 md:text-left md:pr-8">
          <h2 className="mt-8 text-4xl font-bold leading-tight text-gray-800 md:text-4xl md:leading-none lg:text-5xl xl:text-6xl">Osterpralinen Gutschein</h2>
          <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">
            Scotty ist ein blauer Hase, der Karotten liebt 
            und zu Ostern haben wir mit ihm eine Praline entwickelt. 
            Aus gegebenem Anlass gibt's die jetzt erstmal <span className="font-bold border-canorange border-b-2">als Gutschein.*</span></p>
          <div className="mt-4">
            <h3 className="font-bold text-2xl lg:text-3xl">Bunny Wild mit Karotte</h3>
            <p className="">18 Stk. Osterpralinen von Scotty the Blue Bunny. <br/>Inklusive Einladung zur Abholparty</p>
            <div className="mt-2 font-bold text-5xl leading-none">€ 29,95 </div>
            <div className="w-40 mx-auto md:mx-0 md:flex md:items-end md:justify-start">
              <BuyButton className="" shopifyId="4427965366406" title="KAUFEN" background="#78b657" color="#ffffff" border="none" />
            </div>
            <p className="mt-6 p-3 shadow-lg mb-12 bg-white border border-gray-100">
              <div className="vimeo">
                <iframe 
                    src="https://player.vimeo.com/video/406731826" 
                    title="Happy Easter"
                    width="640" 
                    height="564" 
                    frameB
                    order="0" allow="autoplay; fullscreen" 
                    allowFullScreen>
                </iframe>
              </div>
            </p>       
          </div>
        </div>
      </div>
      <div className="hidden md:block mt-12 bg-canblue h-16 flex items-center justify-center text-center md:mt-0 lg:justify-end lg:text-right"></div>
      <div className="mt-10 px-6 pt-20 pb-28 text-lg text-gray-800 font-light bg-gray-100 md:mt-0">
        <div className="max-w-xl mx-auto">
          <h3 className="text-3xl font-bold text-gray-800 md:text-5xl">* Liebe Freunde, </h3>
          <p className="mt-4">
            die Osterpralinen für Scotty wollten wir diese Woche frisch produzieren.
          </p>
          <p className="mt-4">
            Es gab <span className="font-bold">Abholtermine</span> mit dem Bunny persönlich bei <span className="font-bold">ViniCulture</span> (mit Wein pairing). 
          </p>
          <p className="mt-4">
            Das geht ja nun leider nicht.
          </p>
          <p className="mt-4">
            Aber wir werden den Ostergasmus nicht stornieren, sondern verschieben: <span className="font-bold border-canorange border-b-2">Wir feiern einfach Ostern im… Mai?</span>
          </p>
          <p className="mt-4">
            Damit ihr das schon jetzt verschenken könnt, gibt es einen <span className="font-bold">Gutschein</span>. 
          </p>
          <p className="mt-4">
            Wollt ihr die Gutscheine verschenken? Dann sendet uns rechtzeitig <span className="font-bold">eure Grusstexte</span> fürs Video an kontakt@candide.berlin
          </p>
          <p className="mt-4">Wir wünschen euch allen eine gute und sichere Zeit. <br/><span className="font-bold">Candide &amp; Scotty</span></p>

        </div>
      </div>
        
      <Footer lang={Language} />
    </Layout>
  )
}

export default IndexPage
